.Stack {
  height: 100%;
  padding-top: 30px;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  span {
    position: absolute;
    width: 100%;
    top: 10px;
    text-align: center;
  }
  img {
    max-height: 100%;
  }
}