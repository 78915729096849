.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 250px;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.load-error {
  margin-top: 40px;
  font-size: 14px;
  display: flex;
  flex-flow: column;
  align-items: center;
  .instructions {
    font-size: 11px;
    color: rgba(0,0,0,0.5);
    max-width: 300px;
    text-align: justify;
    text-align-last: center;
  }
  .support-button {
    background: transparent;
    border: none;
    color: #2E5BFF;
    display: inline;
    padding: 0;
    margin: 0;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    &:active {
      color: #752eff;
    }
  }
  .code {
    font-family:'Courier New', Courier, monospace;
  }
  &.hidden {
    display: none;
  }
}